.container img{
    width: 100%;
    max-height: 700px;
    object-fit: cover;
}
.container1{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.container1 img{
    max-width: 350px;
    max-height: 250px;
    object-fit: cover;
}

.container1 p{
    max-width: 300px;
    font-size: medium;
    text-align: left;
    margin: 10px;
    padding: 0 10px;
}
.container2{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
.container2 p{
    max-width: 450px;
    font-size: medium;
    text-align: left;
    margin: 10px;
    padding: 0 10px;
}

 .container3 p{
    max-width: 1200px;
    font-size: medium;
    text-align: left;
    margin: 10px;
    padding: 0 10px;

 }

 .container Form {
    max-width: 450px;
    margin: 0 auto;
    
    
 }




  
